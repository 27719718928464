<template>
  <div>
    <van-nav-bar @click-left="home"  >
      <template #title>
        <span >工单列表</span>
      </template>
      <template #left>
        <van-icon name="arrow-left" size="25px"  />
      </template>
     
    </van-nav-bar>
    <van-empty v-if="list.length == 0" description="暂无数据"></van-empty>
    <van-list
      class="pdb50 "
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="getList"
    >
      <!-- <div class="search">
        <van-search
          v-model="listQuery.keyword"
          show-action
          shape="round"
          left-icon="arrow-down"
          placeholder="请输入搜索关键词"
          @search="handleFilter"
        >
          <template #label>
            <div @click="showStatusPicker = true">
              {{ listQuery.status || "状态" }}
            </div>
          </template>
          <template #action>
            <div @click="handleFilter">搜索</div>
          </template>
        </van-search>
      </div> -->

      <van-cell
        :border=false
        class="one"
        v-for="(item, index) in list"
        :key="index"
        :to="{ path: '/gongdan/detail', query:{ id: item.id ,status:listQuery.status} }"
      >
        <template #title>
          <div class="flex-row fs14">
            <div class="flex-grow-1 fwb">{{ item.orderName }}</div>
            <div class="flex-grow-0">
              <van-tag :type="getByStatus(item.status, 'type')">{{
                getByStatus(item.status, "text")
              }}</van-tag>
            </div>
          </div>
        </template>
        <template #label>
          <div class="flex-col mgt5 fs13">
            <div class="flex-grow-0 color666">
              {{ item.orderContent }}
            </div>
            <div class="flex-grow-0 flex-row mgt5 color999">
              <div class="item flex-grow-1">
                工单等级：{{ item.orderLevel }}
              </div>
              <div class="item flex-grow-0 flex-row">
                创建时间：{{ parseTime(item.createTime, "{y}-{m}-{d}") }}
              </div>
            </div>
              <div class="flex-grow-0 flex-row mgt5 color999">
              指派人：{{ item.assignName }}
            </div>
              <div class="flex-grow-0 flex-row mgt5 color999">
              站点名称:{{ item.siteName }}
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>

    <!-- 告警状态选择 -->
    <van-popup v-model="showStatusPicker" position="bottom">
      <van-picker
        title="选择状态"
        show-toolbar
        :columns="statusList"
        @confirm="selectStatus"
        @cancel="showStatusPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getGongdanList } from "@/api/gongdan";
import { formatParams } from "@/utils/params";
import {
  Button,
  Cell,
  Empty,
  List,
  Picker,
  Popup,
  Search,
  Tag,
  Toast,
  NavBar,
  Icon
} from "vant";
import Header from "@/components/header";
export default {
  name: "XiansuoResult",
  components: {
    Header,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Picker.name]: Picker,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,

  },
  props: {},
  data() {
    return {
      loading: false,
      finished: false,
      statusList: ["状态1", "状态2", "状态3", "状态4"],
      sortlist:[],
      listQuery: {
        keyword: "",
        status: "",
        pageNo: 1,
        limit: 10
      },
      list: [],
      showStatusPicker: false
    };
  },
  watch: {},
  created() {
    this.parseTime = this.utils.parseTime;
    const status = this.$route.query.status || "";
    this.listQuery.status = status;
  },
  mounted() { },
  methods: {
     home() {
      this.$router.replace('/');
    },
    getList() {
      this.loading = true;
      const params = formatParams(
        {
          "Q^ORDER_NAME_^SL": this.listQuery.keyword,
          "Q^STATUS_^S": this.listQuery.status
        },
        { page: this.listQuery.pageNo }
      );
      getGongdanList(params)
        .then(response => {
          this.loading = false;
          let list = this.list;
          let page = parseInt(this.listQuery.pageNo);
          if (this.listQuery.pageNo == 1) {
            list = response.data.dataResult;
          } else {
            list = list.concat(response.data.dataResult);
          }
          if (
            response.data.pageResult.page ==
            response.data.pageResult.totalPages ||
            response.data.pageResult.totalCount == 0
          ) {
            this.finished = true;
          }
          this.listQuery.pageNo = page + 1;
          this.list = list;
        })
        .catch(err => {
          Toast.fail(err);
        });
    },
    selectStatus(value) {
      this.listQuery.status = value;
      this.showStatusPicker = false;
    },
    // 查询
    handleFilter() {
      this.listQuery.pageNo = 1;
      this.loading = true;
      this.finished = false;
      this.getList();
    },
    //根据类型获取状态
    getByStatus(status, type) {
      //工单状态 0待分配 1待处理 2已处理 3已提交
      const statusArr = [
        {
          type: "warning",
          text: "待分配"
        },
        {
          type: "danger",
          text: "待处理"
        },
        {
          type: "success",
          text: "已处理"
        },
        {
          type: "primary",
          text: "已提交"
        }
      ];
      return statusArr[status][type];
    }
  }
};
</script>
<style lang="scss" scoped>
.van-cell{
  margin-bottom: 0.1rem;
  margin-top: 0 !important;
}

.van-search__label {
  color: #1989fa;
}
</style>
